import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button, OverlayTrigger, Popover, Row, Col } from "react-bootstrap";
import moment from "moment";

//ACTIONS
import * as GlobalVarsActions from "../../../../../../../../store/actions/globalVars";
import * as BookingsActions from "../../../../../../../../store/actions/bookings";

//STYLES
import * as TemplatesStyles from "./styles";

function Timetable(props) {
    const dispatch = useDispatch();

    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    const user = useSelector((state) => state.user);
    const organisation = useSelector((state) => state.organisation);
    const userProfile = useSelector((state) => state.userProfile);
    const globalVars = useSelector((state) => state.globalVars);
    const bookingData = useSelector((state) => state.bookingData);

    const topDatesRow = useRef(null);
    const dataBody = useRef(null);

    const [settings, setSettings] = useState({
        dayIndex: 0,
        weekSystemUUID: "",
        pastWeek: false
    });

    const [layout, setLayout] = useState({
        slotClass: "",
        dayClass: "",
        currentDayClass: "",
        days: [],
        order: [],
        sessions: [],
        sessionTotal: 0
    });

    const [modal, setModal] = useState({
        open: false,
        heading: "",
        message: ""
    });

    function handleModalClose() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    useEffect(() => {
        if (globalVars.roomDetail.layoutData.layout == "Timetable") {
            setup();
        }
    }, []);

    function setup() {
        const layoutDays = props.layoutData.days.split(",");
        const days = [];
        let newSessions = {};
        const order = props.layoutData.sessionOrder.split(",");

        for (const day of layoutDays) {
            days.push(day == "true");
        }

        for (const session of props.layoutData.sessionData) {
            newSessions[session.id] = session;
        }

        let totalDays = 0;
        for (const [index, day] of days.entries()) {
            if (day) {
                totalDays++;
            }
        }

        setLayout((prevState) => {
            return {
                ...prevState,
                days: days,
                sessions: newSessions,
                sessionTotal: props.layoutData.sessions,
                order: order,
                slotClass: "session" + totalDays + "_slot",
                dayClass: "session" + totalDays + "_days"
                //currentDayClass: "session" + totalDays + "_days timetable-layout currentDay"
            };
        });
    }

    function handleBookClick(event) {
        if (user.userDepartments == "") {
            setModal({
                heading: "No Departments",
                message: "You cannot book as you have no department assign to you. Please contact you organisation's senior admin",
                open: true
            });
        } else {
            if (!organisation.locked && userProfile.room_Write) {
                const { id } = event.target;
                const IDs = id.toString().split("-");

                dispatch(GlobalVarsActions.UpdateRoomSessionID(id));

                dispatch(GlobalVarsActions.UpdateRoomWeekBegin(bookingData.dayDates[0]));
                dispatch(GlobalVarsActions.UpdateRoomDate(bookingData.dayDates[IDs[0]]));
                dispatch(GlobalVarsActions.UpdateRoomTotalSessions(layout.sessionTotal));

                dispatch(GlobalVarsActions.UpdateBookingOpen());
            }
        }
    }

    function handleEditClick(data, sessionID) {
        dispatch(GlobalVarsActions.UpdateBookingEdit({ ...data, sessionID: sessionID }));
    }

    function handleScrollDown() {
        dataBody.current.scrollTo(0, dataBody.current.scrollTop + 60);
    }

    function handleScrollUp() {
        dataBody.current.scrollTo(0, dataBody.current.scrollTop - 60);
    }

    return (
        <div
            style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column"
                // border: "2px solid orange"
            }}
        >
            <Row ref={topDatesRow}>
                <Col sm={1}></Col>
                <Col sm={10}>
                    <table style={TemplatesStyles.layout} width="100%" border="1px">
                        <thead>
                            <tr>
                                <td style={{ ...TemplatesStyles.layout, ...TemplatesStyles[layout.slotClass] }}>Session</td>
                                {layout.days.map((day, index) => {
                                    if (day) {
                                        return (
                                            <td
                                                style={
                                                    bookingData.currentDay === bookingData.dayDates[index]
                                                        ? {
                                                              ...TemplatesStyles[layout.dayClass],
                                                              ...TemplatesStyles.layout,
                                                              ...TemplatesStyles.currentDay
                                                          }
                                                        : {
                                                              ...TemplatesStyles[layout.dayClass],
                                                              ...TemplatesStyles.layout
                                                          }
                                                }
                                                key={index}
                                            >
                                                {dayNames[index]} <br /> {bookingData.dayDates[index]}
                                            </td>
                                        );
                                    }
                                })}
                            </tr>
                        </thead>
                    </table>
                </Col>
                <Col sm={1}></Col>
            </Row>
            {topDatesRow.current != null && (
                <Row style={{ height: globalVars.mainBodyHeight - topDatesRow.current.clientHeight - 10 }}>
                    <Col sm={1}></Col>
                    <Col sm={10} ref={dataBody} style={{ height: "100%", overflow: "scroll" }}>
                        <table style={TemplatesStyles.layout} width="100%" border="1px">
                            <tbody>
                                {layout.order.map((session, index) => {
                                    let placement = "bottom";
                                    if (index >= layout.order.length - 3) {
                                        placement = "top";
                                    }
                                    if (session.includes("b")) {
                                        return (
                                            <tr key={index} style={{ backgroundColor: layout.sessions[session].bgColor }}>
                                                <td
                                                    style={{
                                                        ...TemplatesStyles.layout,
                                                        ...TemplatesStyles[layout.slotClass],
                                                        color: layout.sessions[session].textColor
                                                    }}
                                                >
                                                    {layout.sessions[session].breakText != ""
                                                        ? layout.sessions[session].breakText
                                                        : layout.sessions[session].id}
                                                </td>
                                                {layout.days.map((day, index) => {
                                                    if (day) {
                                                        const name =
                                                            bookingData.week +
                                                            "-" +
                                                            formatString(index) +
                                                            "-" +
                                                            formatString(layout.sessions[session].id);
                                                        if (bookingData.data[name] != null) {
                                                            if (bookingData.data[name].type == "single") {
                                                                return (
                                                                    <SingleSlot
                                                                        key={index}
                                                                        index={index}
                                                                        session={session}
                                                                        data={bookingData.data[name]}
                                                                        placement={placement}
                                                                        edit={handleEditClick.bind(
                                                                            this,
                                                                            bookingData.data[name],
                                                                            `${index}-${session}`
                                                                        )}
                                                                        dayClass={layout.dayClass}
                                                                    />
                                                                );
                                                            } else if (bookingData.data[name].type == "repeat") {
                                                                return (
                                                                    <RepeatSlot
                                                                        key={index}
                                                                        index={index}
                                                                        session={session}
                                                                        data={bookingData.data[name]}
                                                                        placement={placement}
                                                                        edit={handleEditClick.bind(
                                                                            this,
                                                                            bookingData.data[name],
                                                                            `${index}-${session}`
                                                                        )}
                                                                        dayClass={layout.dayClass}
                                                                    />
                                                                );
                                                            } else {
                                                                return (
                                                                    <EmtpySlot
                                                                        key={index}
                                                                        index={index}
                                                                        session={session}
                                                                        book={handleBookClick}
                                                                        dayClass={layout.dayClass}
                                                                    />
                                                                );
                                                            }
                                                        } else {
                                                            return <LoadingSlot key={index} />;
                                                        }
                                                    }
                                                })}
                                            </tr>
                                        );
                                    } else {
                                        return (
                                            <tr key={index}>
                                                <td style={{ ...TemplatesStyles[layout.slotClass], ...TemplatesStyles.layout }}>
                                                    {layout.sessions[session].customText != ""
                                                        ? layout.sessions[session].customText
                                                        : layout.sessions[session].id}
                                                </td>
                                                {layout.days.map((day, index) => {
                                                    if (day) {
                                                        const name =
                                                            bookingData.week +
                                                            "-" +
                                                            formatString(index) +
                                                            "-" +
                                                            formatString(layout.sessions[session].id);
                                                        if (bookingData.data[name] != null) {
                                                            if (bookingData.data[name].type == "single") {
                                                                return (
                                                                    <SingleSlot
                                                                        key={index}
                                                                        index={index}
                                                                        session={session}
                                                                        data={bookingData.data[name]}
                                                                        placement={placement}
                                                                        edit={handleEditClick.bind(
                                                                            this,
                                                                            bookingData.data[name],
                                                                            `${index}-${session}`
                                                                        )}
                                                                        dayClass={layout.dayClass}
                                                                    />
                                                                );
                                                            } else if (bookingData.data[name].type == "repeat") {
                                                                return (
                                                                    <RepeatSlot
                                                                        key={index}
                                                                        index={index}
                                                                        session={session}
                                                                        data={bookingData.data[name]}
                                                                        placement={placement}
                                                                        edit={handleEditClick.bind(
                                                                            this,
                                                                            bookingData.data[name],
                                                                            `${formatString(index)}-${formatString(session)}`
                                                                        )}
                                                                        dayClass={layout.dayClass}
                                                                    />
                                                                );
                                                            } else {
                                                                return (
                                                                    <EmtpySlot
                                                                        key={index}
                                                                        index={index}
                                                                        session={session}
                                                                        book={handleBookClick}
                                                                        dayClass={layout.dayClass}
                                                                    />
                                                                );
                                                            }
                                                        } else {
                                                            return <LoadingSlot key={index} />;
                                                        }
                                                    }
                                                })}
                                            </tr>
                                        );
                                    }
                                })}
                            </tbody>
                        </table>
                    </Col>
                    <Col sm={1}>
                        <div style={TemplatesStyles.buttons}>
                            <div className="scrollBar_upButton" onClick={handleScrollUp}>
                                <i className="fa-solid fa-angle-up"></i>
                            </div>
                            <div className="scrollBar_downButton" onClick={handleScrollDown}>
                                <i className="fa-solid fa-angle-down"></i>
                            </div>
                        </div>
                    </Col>
                </Row>
            )}

            <Modal show={modal.open} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

function LoadingSlot() {
    return <td style={TemplatesStyles.loadingLayout}>Loading...</td>;
}

function EmtpySlot(props) {
    const organisation = useSelector((state) => state.organisation);
    const globalVars = useSelector((state) => state.globalVars);
    const userProfile = useSelector((state) => state.userProfile);

    return (
        <td
            className={!organisation.locked && userProfile.room_Write ? "custom_emptySlot" : "custom_emptySlotDisabled"}
            style={
                globalVars.roomDetail.sessionID == `${props.index}-${props.session}`
                    ? TemplatesStyles.layoutSelected
                    : { ...TemplatesStyles.layout, ...TemplatesStyles[props.dayClass] }
            }
            key={props.index}
            id={props.index + "-" + props.session}
            onClick={props.book}
        >
            Book
        </td>
    );
}

function SingleSlot(props) {
    const user = useSelector((state) => state.user);
    const userProfile = useSelector((state) => state.userProfile);

    let canModify = false;

    if (user.uuid == props.data.userID) {
        canModify = true;
    } else {
        if (userProfile.room_Edit) {
            canModify = true;
        }
        if (userProfile.room_Delete) {
            canModify = true;
        }
    }

    return (
        <>
            <OverlayTrigger
                id="overLay"
                placement={props.placement}
                delay={{ show: 1000, hide: 0 }}
                overlay={
                    <Popover style={{ width: "100%" }}>
                        <Popover.Header>More Details...</Popover.Header>
                        <Popover.Body>
                            <strong>Class: </strong>
                            {props.data.sessionDes} <br />
                            <strong>Session Length: </strong> {props.data.sessionTotal}
                            <br />
                            <strong>Booking Type: </strong> Single
                            {props.data.comments != "" && (
                                <>
                                    <br />
                                    <strong>Comments: </strong>
                                    {props.data.comments}
                                </>
                            )}
                            {canModify && (
                                <div>
                                    <br />
                                    <div style={{ textAlign: "center" }}>
                                        <strong>Double Click to Modify</strong>
                                    </div>
                                </div>
                            )}
                        </Popover.Body>
                    </Popover>
                }
            >
                <td
                    style={{ ...TemplatesStyles.layout, ...TemplatesStyles[props.dayClass], ...TemplatesStyles.singleSlot }}
                    key={props.index}
                    id={props.index + "-" + props.session}
                    onDoubleClick={canModify ? props.edit : null}
                >
                    {props.data.user} <br /> {GetDepartment(props.data.department)}
                </td>
            </OverlayTrigger>
        </>
    );
}

function RepeatSlot(props) {
    const user = useSelector((state) => state.user);
    const userProfile = useSelector((state) => state.userProfile);

    let canModify = false;

    if (user.uuid == props.data.userID) {
        canModify = true;
    } else {
        if (userProfile.room_Edit) {
            canModify = true;
        }
        if (userProfile.room_Delete) {
            canModify = true;
        }
    }

    return (
        <>
            <OverlayTrigger
                id="overLay"
                placement={props.placement}
                delay={{ show: 1000, hide: 0 }}
                overlay={
                    <Popover style={{ width: "100%" }}>
                        <Popover.Header>More Details...</Popover.Header>
                        <Popover.Body>
                            <strong>Class: </strong>
                            {props.data.sessionDes} <br />
                            <strong>Session Length: </strong> {props.data.sessionTotal}
                            <br />
                            <strong>Booking Type: </strong> Repeat - {props.data.repeatType} <br />
                            <strong>Repeat Until: </strong> {props.data.repeatUntil}
                            {props.data.comments != "" && (
                                <>
                                    <br />
                                    <strong>Comments: </strong>
                                    {props.data.comments}
                                </>
                            )}
                            {canModify && (
                                <div>
                                    <br />
                                    <div style={{ textAlign: "center" }}>
                                        <strong>Double Click to Modify</strong>
                                    </div>
                                </div>
                            )}
                        </Popover.Body>
                    </Popover>
                }
            >
                <td
                    style={{ ...TemplatesStyles.layout, ...TemplatesStyles[props.dayClass], ...TemplatesStyles.repeatSlot }}
                    key={props.index}
                    id={props.index + "-" + props.session}
                    onDoubleClick={canModify ? props.edit : null}
                >
                    {props.data.user} <br /> {GetDepartment(props.data.department)}
                </td>
            </OverlayTrigger>
        </>
    );
}

//SCRIPT GLOBAL METHODS
function formatString(time) {
    if (time.toString().includes("b")) {
        time = time.replace("b", "");

        if (time.toString().length == 1) {
            return "b0" + time;
        } else {
            return time;
        }
    } else {
        if (time.toString().length == 1) {
            return "0" + time;
        } else {
            return time;
        }
    }
}

function GetDepartment(id) {
    const organisation = useSelector((state) => state.organisation);

    for (const department of organisation.departments) {
        if (department.uuid == id) {
            return department.name;
        }
    }
}

export default Timetable;
