import Axios from "axios";
import moment from "moment";

export const SET_DATA = "SET_DATA";

export const UpdateBookingData = (data, orgID, roomID, weekSlot, days, dayNames, weekBG) => {
    return (dispatch, getState) => {
        const dayDates = [];

        const wd = dayNames.split(",");

        for (const [index, value] of wd.entries()) {
            if (value == "true") {
                const week = moment(weekBG);
                dayDates.push(moment(week.add(index, "d")).format("DD/MM/YYYY"));
            } else {
                dayDates.push("");
            }
        }

        dispatch({
            type: SET_DATA,
            data: data,
            orgID: orgID,
            roomID: roomID,
            week: weekSlot,
            days: days,
            dayDates: dayDates,
            currentDay: moment().format("DD/MM/YYYY"),
            weekBG: weekBG
        });
    };
};

export const GetData = (roomID, weekBG, days) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const user = getState().user;
            // const globalVars = getState().globalVars;
            const organisation = getState().organisation;

            const weekSlot = formatString(weekBG.week()) + "-" + weekBG.format("YY");

            const mySQLData = {
                orgID: user.orgID,
                room: roomID,
                week: weekSlot,
                days: days,
                weekBG: weekBG.format("DD/MM/YYYY")
            };
            console.log(mySQLData);
            Axios.post("/bookings/getBookings", mySQLData)
                .then((res) => {
                    const recievedData = res.data;
                    console.log(recievedData.bookings);
                    dispatch({
                        type: SET_DATA,
                        data: recievedData.bookings,
                        orgID: user.orgID,
                        roomID: roomID,
                        week: weekSlot,
                        days: days,
                        dayDates: getState().bookingData.dayDates,
                        currentDay: getState().bookingData.currentDay,
                        weekBG: weekBG,
                        loaded: true
                    });
                    resolve();
                })
                .catch((err) => {
                    console.log(err);
                });
        });
    };
};

function formatString(time) {
    if (time.toString().includes("b")) {
        time = time.replace("b", "");

        if (time.toString().length == 1) {
            return "b0" + time;
        } else {
            return time;
        }
    } else {
        if (time.toString().length == 1) {
            return "0" + time;
        } else {
            return time;
        }
    }
}
