import * as ReducerActions from "../actions/bookingData";

const initalState = {
    orgID: "",
    roomID: "",
    week: "",
    days: "",
    weekBG: "",
    dayDates: "",
    currentDay: "",
    data: {}
};

export default (state = initalState, action) => {
    switch (action.type) {
        case ReducerActions.SET_DATA:
            return {
                ...state,
                orgID: action.orgID,
                data: action.data,
                roomID: action.roomID,
                week: action.week,
                days: action.days,
                dayDates: action.dayDates,
                currentDay: action.currentDay,
                weekBG: action.weekBG,
                loaded: action.loaded
            };
        default:
            return state;
    }
};
